/* eslint-disable no-console */
import firebase from "firebase/app";
import { readCCPACookie, writeCCPACookie } from "@/common/functions";
import { NEWSLETTERS, API_SERVICE_MAIL } from "@/common/consts";
import axios from "axios";

function config(idToken) {
	return { headers: { Authorization: `Bearer ${idToken}` } };
}

export default {
	state: {
		loggedIn: false,
		user: {
			data: null,
			usprivacy: "1---",
			newslettersSubscription: null,
		},
		isDialogOpen: false,
		isLoggingIn: false,
		logInError: "",
		previouslyLoggedIn: false,
		location: null,
		isSubscriptionDialogOpen: false,
	},
	actions: {
		fetchUser: ({ commit, dispatch }, user) => {
			dispatch("getLocation");
			commit("SET_LOGGED_IN", user !== null);
			if (user) {
				commit("UPDATE_USER", {
					displayName: user.displayName,
					email: user.email,
					picture: user.photoURL,
				});
				dispatch("fetchUserClaims");
				dispatch("getUserNewslettersSubscription");
			} else {
				commit("UPDATE_USER", null);
			}
		},
		fetchUserClaims: ({ commit }) => {
			if (firebase.apps.length) {
				firebase
					.auth()
					.currentUser.getIdTokenResult(true)
					.then((idTokenResult) => {
						let usprivacy =
							idTokenResult.claims &&
							idTokenResult.claims.usprivacy;
						commit("UPDATE_USPRIVACY", usprivacy || "1YNY");
					});
			}
		},
		login: ({ dispatch, commit }) => {
			commit("SET_IS_LOGGING_IN", true);
			commit("SET_LOG_IN_ERROR", null);
			if (firebase.apps.length) {
				firebase
					.auth()
					.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
					.then(() => {
						const provider = new firebase.auth.GoogleAuthProvider();
						provider.addScope("profile");
						provider.addScope("email");

						return firebase.auth().signInWithPopup(provider);
					})
					.then(() => {
						commit("SET_DIALOG_STATE", false);
						commit("SET_IS_LOGGING_IN", false);
					})
					.catch((error) => {
						commit("SET_IS_LOGGING_IN", false);
						if (error.code !== "auth/popup-closed-by-user") {
							commit("SET_LOG_IN_ERROR", error.message);
						}
					});
			}
		},
		logout: ({ commit }) => {
			if (firebase.apps.length) {
				firebase
					.auth()
					.signOut()
					.then(() => {
						commit("SET_LOGGED_IN", false);
						commit("SET_USER_SUBSCRIPTION", null);
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},
		getUserNewslettersSubscription: ({ state, commit, dispatch }) => {
			if (firebase.apps.length) {
				firebase
					.auth()
					.currentUser.getIdToken()
					.then((idToken) => {
						axios
							.get(
								`${API_SERVICE_MAIL}/newsletter`,
								config(idToken)
							)
							.then((response) => {
								const { data } = response.data;
								if (data.status) {
									commit(
										"SET_USER_SUBSCRIPTION",
										data.status
									);
								} else {
									state.location === "US"
										? dispatch(
												"toggleNewslettersSubscription",
												NEWSLETTERS.ON
										  )
										: commit(
												"SET_SUBSCRIPTION_DIALOG_STATE",
												true
										  );
								}
							})
							.catch((err) => {
								console.error(err);
							});
					});
			}
		},
		toggleNewslettersSubscription: ({ commit, dispatch }, value) => {
			const params = {
				status: value,
				website: NEWSLETTERS.WEBSITE,
			};
			if (firebase.apps.length) {
				firebase
					.auth()
					.currentUser.getIdToken()
					.then((idToken) => {
						axios
							.put(
								`${API_SERVICE_MAIL}/newsletter`,
								params,
								config(idToken)
							)
							.then((response) => {
								const { data } = response.data;
								commit("SET_USER_SUBSCRIPTION", data.status);
							})
							.catch((err) => {
								console.error(err);
							});
					});
			}
		},
		getLocation({ commit }) {
			axios
				.get(
					"https://pro.ip-api.com/json/?key=AmUN9xAaQALVYu6&fields=countryCode"
				)
				.then((response) => {
					const { data } = response;
					commit("SET_LOCATION", data.countryCode);
				});
		},
	},
	mutations: {
		SET_LOGGED_IN: (state, value) => {
			state.loggedIn = value;
		},
		SET_IS_LOGGING_IN: (state, value) => {
			state.isLoggingIn = value;
		},
		SET_LOG_IN_ERROR: (state, value) => {
			state.logInError = value;
		},
		UPDATE_USER: (state, data) => {
			state.user.data = data;
		},
		UPDATE_USPRIVACY: (state, value) => {
			if (!/^[1][nNyY-][nNyY-][nNyY-]$/.test(value)) return;
			state.user.usprivacy = value;

			// Sync the new value to the 'usprivacy' cookie that is read from the "__uspapi()"
			//console.log(`DEBUG> usprivacy: claim=${value} cookie=${readCCPACookie()}`);
			if (value != readCCPACookie()) {
				writeCCPACookie(value);
				//console.log(`DEBUG> Updated cookie: ${readCCPACookie()}`);
			}
		},
		SET_DIALOG_STATE: (state, value) => {
			state.isDialogOpen = value;
		},
		SET_PREVIOUSLY_LOGGED_IN: (state, value) => {
			state.previouslyLoggedIn = value;
		},
		SET_SUBSCRIPTION_DIALOG_STATE: (state, value) => {
			state.isSubscriptionDialogOpen = value;
		},
		SET_LOCATION: (state, value) => {
			state.location = value;
		},
		SET_USER_SUBSCRIPTION: (state, value) => {
			state.user.newslettersSubscription = value;
		},
	},
	getters: {
		loggedIn: (state) => state.loggedIn,
		user: (state) => state.user,
		imgProfile: (state) => state.user.data.picture,
		initials: (state) => {
			return state.user.data.displayName
				.split(" ")
				.map((word) => word[0].toUpperCase())
				.join("");
		},
		usprivacy: (state) => state.user.usprivacy,
		isDialogOpen: (state) => state.isDialogOpen,
		previouslyLoggedIn: (state) => state.previouslyLoggedIn,
	},
};
