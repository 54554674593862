export const dateAsObject = (date = new Date()) => {
	return {
		day: date.getDate(),
		month: date.toLocaleString("en-US", { month: "long" }),
		year: date.getFullYear(),
	};
};

export const currentDateAsObject = dateAsObject;

export const dateObjectAsDailyPuzzleLink = (dateObject) => {
	return `/daily-puzzle/${dateObject.month.toLowerCase()}-${dateObject.day}-${
		dateObject.year
	}-answers/`;
};
