import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import featureToggling from "./modules/FeatureTogglingModule";
import login from "./modules/LoginModule";

Vue.use(Vuex);

const store = new Vuex.Store({
	state,
	actions,
	mutations,
	getters,
	modules: {
		features: featureToggling,
		login,
	},
});

export default store;
