/* eslint-disable */
import axios from "axios";
import Vue from "vue";

var signaled = typeof window.__PRERENDER_INJECTED === "undefined"; // Assume "signaled" if it's rendering on the client side.
//var signaled = false;
var activeAxiosRequests = 0;
var totalAxiosRequests = 0;
var savedAxiosRequests = 0;
var checkTimeout = null;

function signal(url, hashUrl, response) {
	if (signaled) return;
	let status = response.status;

	// Cache JSON responses:
	try {
		if (
			status === 200 &&
			response.headers &&
			response.headers["content-type"] &&
			response.headers["content-type"].includes("application/json")
		) {
			let responseJson = JSON.stringify({
				status: response.status,
				statusText: response.statusText,
				data: response.data,
				headers: {}, // response.headers
				config: {}, // response.config
			});

			if (responseJson.length < 100000) {
				console.log(
					`SSR: Caching ${responseJson.length} bytes for URL ${url}`
				);
				let code = `ssr_cache[${JSON.stringify(
					hashUrl
				)}]=JSON.parse(${JSON.stringify(responseJson)});`; // Reference: https://gist.github.com/mathiasbynens/d6e10171d44a59bb5664617c64ff2763
				if (savedAxiosRequests === 0) code = "var ssr_cache={};" + code;
				let newScript = document.createElement("script");
				newScript.appendChild(document.createTextNode(code));
				document.head.appendChild(newScript);
				savedAxiosRequests++;
			} else {
				console.log(
					`SSR: Ignoring big JSON response for URL "${url}" with size ${responseJson.length}`
				);
			}
		} else {
			console.log(
				`SSR: Ignoring non-JSON response for URL "${url}" with headers `,
				response.headers
			);
		}
	} catch (error) {
		console.error("Paxios Error: ", error);
	}

	// Signal end of request
	activeAxiosRequests--;
	// eslint-disable-next-line
	console.log(
		`Paxios-- ${activeAxiosRequests} [Total: ${totalAxiosRequests}] - ${status}: ${url}`
	);
	if (activeAxiosRequests === 0) {
		if (checkTimeout) clearTimeout(checkTimeout);

		checkTimeout = setTimeout(function() {
			if (activeAxiosRequests === 0) {
				// eslint-disable-next-line
				console.log(`Paxios requests == 0; Firing spa-render-complete`);
				signaled = true;
				//window.document.dispatchEvent(new CustomEvent("spa-render-complete"));
				Vue.nextTick(() => {
					window.document.dispatchEvent(
						new CustomEvent("spa-render-complete")
					);
				});
			}
		}, 200);
	}
}

function incrementCounter(url) {
	if (!signaled) {
		if (totalAxiosRequests === 0)
			// We just learned that this page requires AXIOS data, so let's inform.
			window.document.dispatchEvent(
				new CustomEvent("spa-render-watchdog")
			);
		activeAxiosRequests++;
		totalAxiosRequests++;
		// eslint-disable-next-line
		console.log(
			`Paxios++ ${activeAxiosRequests} [Total: ${totalAxiosRequests}] - URL: ${url}`
		);
	}
}

function computeHashedUrl(url, config) {
	let queryString = "";
	if (config && config.params)
		queryString =
			"?" +
			Object.keys(config.params)
				.map(key => key + "=" + config.params[key])
				.join("&");
	return btoa(`${url}${queryString}`);
}

function doGet(originalGet, url, hashUrl, config) {
	let promise = new Promise((resolve, reject) => {
		let retry = false;
		if (!signaled) {
			if (!config) config = {};
			if (config.paxiosRetry !== false)
				// Only an explicit 'false' means false; else default to true.
				retry = true;
			if (!config.timeout) config.timeout = retry ? 5000 : 14000;
		}

		originalGet(url, config)
			.then(response => {
				signal(url, hashUrl, response);
				resolve(response);
			})
			.catch(error => {
				// Allow one paxios retry?
				if (retry) {
					console.log(`Paxios: Retrying GET for ${url}`);
					config.timeout = 9000;
					originalGet(url, config)
						.then(response => {
							signal(url, hashUrl, response);
							resolve(response);
						})
						.catch(error => {
							signal(
								url,
								hashUrl,
								error.response || {
									status: error.statusCode,
									statusText: error.message,
								}
							);
							reject(error);
						});
				} else {
					signal(
						url,
						hashUrl,
						error.response || {
							status: error.statusCode,
							statusText: error.message,
						}
					);
					reject(error);
				}
			});
	});
	return promise;
}

function doRequest(originalRequest, url, hashUrl, config) {
	let promise = new Promise((resolve, reject) => {
		let retry = false;
		if (!signaled) {
			if (!config) config = {};
			if (config.paxiosRetry !== false)
				// Only an explicit 'false' means false; else default to true.
				retry = true;
			if (!config.timeout) config.timeout = retry ? 5000 : 14000;
		}

		originalRequest(config)
			.then(response => {
				signal(url, hashUrl, response);
				resolve(response);
			})
			.catch(error => {
				// Allow one paxios retry?
				if (retry) {
					console.log(`Paxios: Retrying GET for ${url}`);
					config.timeout = 9000;
					originalRequest(config)
						.then(response => {
							signal(url, hashUrl, response);
							resolve(response);
						})
						.catch(error => {
							signal(
								url,
								hashUrl,
								error.response || {
									status: error.statusCode,
									statusText: error.message,
								}
							);
							reject(error);
						});
				} else {
					signal(
						url,
						hashUrl,
						error.response || {
							status: error.statusCode,
							statusText: error.message,
						}
					);
					reject(error);
				}
			});
	});
	return promise;
}

// Static paxios.get(url, config):
// paxiosRetry: true (default): Allow the first call to take up to 5s and a retry call up to 9 seconds.
// Example disabling retries: paxios.get(url, {paxiosRetry:false});
function get(url, config) {
	// Protect against cases where the axios library is mocked.
	if (axios.isJestMock) return Promise.resolve({});

	let hashUrl = computeHashedUrl(url, config);
	if (typeof ssr_cache !== "undefined" && ssr_cache[hashUrl]) {
		// eslint-disable-next-line
		console.log(
			`Paxios: Resolving with cached response for URL: ${hashUrl}`
		);
		return Promise.resolve(ssr_cache[hashUrl]);
	}

	incrementCounter(url);
	let promise = doGet(axios.get, url, hashUrl, config);
	return promise;
}

// Creates an axios instance and wraps it with paxios
function create(config) {
	if (axios.isJestMock) return {};

	return wrap(axios.create(config));
}

// Wraps an axios instance to keep track of get requests
function wrap(original) {
	if (axios.isJestMock) return original;

	let originalGet = original.get;
	original.get = (url, config) => {
		let hashUrl = computeHashedUrl(url, config);
		if (typeof ssr_cache !== "undefined" && ssr_cache[hashUrl]) {
			// eslint-disable-next-line
			console.log(
				`Paxios: Resolving with cached response for URL: ${hashUrl}`
			);
			return Promise.resolve(ssr_cache[hashUrl]);
		}

		incrementCounter(url);
		let promise = doGet(originalGet, url, hashUrl, config);
		return promise;
	}; // end of get

	let originalRequest = original.request;
	original.request = options => {
		if (options.method !== "get") return originalRequest(options);

		let url = options.url;
		let hashUrl = computeHashedUrl(url, options);
		if (typeof ssr_cache !== "undefined" && ssr_cache[hashUrl]) {
			// eslint-disable-next-line
			console.log(
				`Paxios: Resolving with cached response for URL: ${hashUrl}`
			);
			return Promise.resolve(ssr_cache[hashUrl]);
		}
		incrementCounter(url);

		let promise = doRequest(originalRequest, url, hashUrl, options);
		return promise;
	}; // end of request

	return original;
}

export default {
	create,
	wrap,
	get,
};
