import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";

import versionService from "@/common/version.service";

import { routes } from "./subdomain.wordscapes";

import { VERSION_CHECK_ERROR } from "@/common/consts";
import store from "@/store";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
	mode: "history",
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) return savedPosition;
		return { x: 0, y: 0 };
	},
});

/** Vue Router afterEach Navigation Guard */
router.afterEach((to, from) => {
	// 0. Check if App has been updated and force reload if that's the case
	versionService.check().catch((err) => {
		if (err.name === VERSION_CHECK_ERROR) {
			window.location.reload(true);
		}
	});

	// 1. Signal SSR-ready for static pages
	if (typeof window.__PRERENDER_INJECTED !== "undefined" && to.meta) {
		console.log(`SSR> Landing on page (${to.name}) ssr='${to.meta.ssr}'`);
		if (to.meta.ssr === "static")
			setTimeout(() => {
				window.document.dispatchEvent(
					new CustomEvent("spa-render-complete")
				);
			}, 300);
		else if (to.meta.ssr === "404")
			setTimeout(() => {
				window.document.dispatchEvent(
					new CustomEvent("spa-render-404")
				);
			}, 300);
	}

	// 2. Signal HTL to render a new layout.
	if (typeof window.__PRERENDER_INJECTED === "undefined") {
		window.$ltkAnalytics.page({
			type: "load",
			path: to.fullPath,
			url: window.location.hostname + to.fullPath,
		});
	}
});

/** Append a trailing slash when it is missing */
router.beforeEach((to, from, next) => {
	if (to.path.indexOf("?") === -1 && to.path.substr(-1) !== "/") {
		next(to.path + "/");
	} else {
		next();
	}
});

/** Vue Router beforeEach Navigation Guard */
router.beforeEach((to, from, next) => {
	const fromSearch = store.state.fromSearch || false;
	// 1. Preset Query if from search by user
	if (fromSearch) {
		if (to.params) {
			if (to.params.level) {
				store.commit("updateQuery", to.params.level);
			} else if (to.params.pack) {
				store.commit("updateQuery", to.params.pack);
			} else if (to.params.group) {
				store.commit("updateQuery", to.params.group);
			}
		}
	} else {
		store.commit("updateQuery", "");
	}

	store.commit("updateCurrentPage", to.name);
	next();
});

export default router;
