import DynamicPage from "@/pages/dynamic-page"; // Avoid code splitting in this page.
import { PAGES } from "@/common/consts";

export const routes = [
	{
		path: "/",
		name: "Home",
		component: () =>
			import(/* webpackChunkName: "page-home" */ "@/pages/home"),
		meta: { ssr: "static" },
		pathToRegexpOptions: { strict: true },
	},
	{
		// Dynamic page:
		path: "/dynamic-page/",
		name: "DynamicPage",
		component: DynamicPage,
		meta: { ssr: "static" },
	},
	{
		path: "/answers/:group/",
		name: "GroupPage",
		component: () =>
			import(
				/* webpackChunkName: "page-group-page" */ "@/pages/group-page"
			),
		meta: { ssr: "static" },
		pathToRegexpOptions: { strict: true },
	},
	{
		path: "/answers/:group/:pack/",
		name: "PackPage",
		component: () =>
			import(
				/* webpackChunkName: "page-pack-page" */ "@/pages/pack-page"
			),
		meta: { ssr: "static" },
		pathToRegexpOptions: { strict: true },
	},
	{
		path: "/answers/:group/:pack/:level/",
		name: "LevelPage",
		component: () =>
			import(
				/* webpackChunkName: "page-level-page" */ "@/pages/level-page"
			),
		meta: { ssr: "dynamic" },
		pathToRegexpOptions: { strict: true },
	},
	{
		path: "/daily-puzzle/",
		name: PAGES.DAILY_PUZZLE_INDEX,
		component: () =>
			import(
				/* webpackChunkName: "page-daily-puzzle-page" */ "@/pages/daily-puzzle-page"
			),
		meta: { ssr: "static" },
		pathToRegexpOptions: { strict: true },
	},
	{
		path: "/daily-puzzle/:date/",
		name: PAGES.DAILY_PUZZLE_ANSWERS,
		component: () =>
			import(
				/* webpackChunkName: "page-daily-puzzle-answers" */ "@/pages/daily-puzzle-answers"
			),
		meta: { ssr: "dynamic" },
		pathToRegexpOptions: { strict: true },
	},
	{
		path: "/feature-toggling",
		name: "feature-toggling",
		component: () =>
			import(
				/* webpackChunkName: "feature-toggling" */ "@/pages/feature-toggling"
			),
	},
	{
		// Soft 404 Pages:
		path: "*",
		name: "NotFound",
		component: () =>
			import(
				/* webpackChunkName: "page-page-not-found" */ "@/pages/not-found"
			),
		meta: { ssr: "404" },
	},
	{
		path: "/answers",
		redirect: {
			name: "Home",
		},
	},
];

// Map of routes accessible by name (this is meant for unit testing purposes where it's useful to be able to pick routes by its name)
export const routesMap = routes.reduce(function(map, route) {
	map[route.name] = route;
	return map;
}, {});
