import state from "@/store/state";
import {
	SMALL_DEVICE_WIDTH_MAX,
	MEDIUM_DEVICE_WIDTH_MAX,
	LARGE_DEVICE_WIDTH_MAX,
} from "@/common/consts";

export default {
	computed: {
		isSmallDevice() {
			return (
				state.window.windowWidth !== null &&
				state.window.windowWidth <= SMALL_DEVICE_WIDTH_MAX
			);
		},
		isMediumDevice() {
			return (
				state.window.windowWidth !== null &&
				state.window.windowWidth <= MEDIUM_DEVICE_WIDTH_MAX &&
				state.window.windowWidth > SMALL_DEVICE_WIDTH_MAX
			);
		},
		isSmallOrMediumDevice() {
			return this.isSmallDevice || this.isMediumDevice;
		},
		isLargeDevice() {
			return (
				!this.isSmallOrMediumDevice &&
				state.window.windowWidth !== null &&
				state.window.windowWidth <= LARGE_DEVICE_WIDTH_MAX
			);
		},
		isMediumDeviceOrWider() {
			return state.window.windowWidth !== null && !this.isSmallDevice;
		},
		isLargeDeviceOrWider() {
			return (
				!this.isSmallOrMediumDevice &&
				state.window.windowWidth !== null &&
				state.window.windowWidth > MEDIUM_DEVICE_WIDTH_MAX
			);
		},
		isExtraLargeDevice() {
			return (
				state.window.windowWidth !== null &&
				state.window.windowWidth > LARGE_DEVICE_WIDTH_MAX
			);
		},
	},
};
