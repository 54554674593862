import Vue from "vue";
import VueI18n from "vue-i18n";

import { SITE_LANGUAGE } from "@/common/consts";
Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: SITE_LANGUAGE,
	messages: {},
	silentTranslationWarn: true,
});

const loadTranslations = () => {
	return import(/* webpackChunkName: "translations" */ `./translations/en.js`)
		.then((messages) => {
			i18n.mergeLocaleMessage(SITE_LANGUAGE, messages);
		})
		.catch((error) => {
			console.log("Could not import translations.", error);
		});
};

loadTranslations();

export default i18n;
