/**
 * Minimum amount of time in milliseconds between versionService calls.
 */
export const VERSION_CHECK_POLL_FREQ = 30000;

/**
 * Errors
 */
export const VERSION_CHECK_ERROR = "VersionError";

/**
 * Maximum widths for each device category. Used for ads, as well as UI behaviour
 * differentiation between mobile, tablet, desktop, etc.
 */
export const SMALL_DEVICE_WIDTH_MAX = 767;
export const MEDIUM_DEVICE_WIDTH_MAX = 1024;
export const LARGE_DEVICE_WIDTH_MAX = 1200;

/**
 * List of supported languages
 */
export const LANG_EN = "en";

/**
 * The site language
 */
export const SITE_LANGUAGE = LANG_EN;

/**
 * URL for Wordscapes Service. It's taken from process environment variables
 */
export const STAGE = process.env.STAGE;
export const API_SERVICE = process.env.API_SERVICE;
export const API_SERVICE_MAIL = process.env.API_SERVICE_MAIL;

/**
 * config for vue-gtag plugin https://matteo-gabriele.gitbook.io/vue-gtag/
 */
const GTAG_IDS = {
	WORDSCAPES: "UA-209673812-2",
	ALL_WF_PRODUCTS: "UA-209673812-3",
	OLD_WORDSCAPES: "UA-16913401-36",
};

export const GTAG_CONFIG = {
	config: { id: GTAG_IDS.WORDSCAPES },
	includes: [
		{ id: GTAG_IDS.ALL_WF_PRODUCTS },
		{ id: GTAG_IDS.OLD_WORDSCAPES },
	],
};

export const PAGES = {
	HOME: "Home",
	DAILY_PUZZLE_ANSWERS: "DailyPuzzleAnswers",
	DAILY_PUZZLE_INDEX: "DailyPuzzlePage",
	DYNAMIC: "DynamicPage",
	GROUP: "GroupPage",
	PACK: "PackPage",
	LEVEL: "LevelPage",
	NOT_FOUND: "NotFound",
};

/**
 * The prefered protocol to be used for canonical meta tags
 */
export const CANONICAL_PROTOCOL = "https:";

/**
 * The prefered host name for canonical meta tags
 */
export const CANONICAL_HOST = "wordscapes.yourdictionary.com";

/**
 * Level out of range message
 */
export const OUT_OF_RANGE = "Level out of range";

/**
 * Min date for daily puzzle answers
 */
export const DAILY_PUZZLE_MIN_DATE = new Date("January 1, 2019");

export {
	AD_LAYOUTS,
	AD_LAYOUT_STUBS,
	AD_PAGETYPES,
	AD_PLACEMENTS,
	AD_POSITIONS,
	ADITUDE_BASE_IDS,
} from "./adConsts";

/**
 * Variables relating to the bonus words
 * shown beside each board.
 */
export const BONUS_WORDS = {
	/**
	 * The max number of bonus words
	 * to show for non-logged in users.
	 */
	GUEST_MAX: 3,
	/**
	 * The max number of bonus words to have
	 * before switching to a 2-column layout
	 */
	SINGLE_COLUMN_MAX: 6,
};

/**
 * The values for newsletters subscription
 */
export const NEWSLETTERS = {
	ON: "subscribed",
	OFF: "unsubscribed",
	WEBSITE: "wordscapes",
};
