import paxios from "@/common/paxios";

const STORAGE_KEYS = {
	ID: "TBL_BUCKET_ID",
	CONFIG: "TBL_BUCKET_CONFIG",
	OVERRIDE: "TBL_BUCKET_CONFIG_OVERRIDE",
};

/**
 * Takes a bucket ID and returns a URL string.
 * @param {Number|String} bucketId The ID of the bucket
 */
const getBucketURL = (bucketId) => {
	return `https://tbl.yourdictionary.com/wordscapes/buckets/current/${bucketId}.json`;
};

/**
 * Gets the bucket ID from local storage, and writes one
 * if one does not exist.
 *
 * @returns {Number} the bucket ID
 */
export const getBucketId = () => {
	let bucketId = localStorage.getItem(STORAGE_KEYS.ID);
	if (typeof bucketId !== "string") {
		bucketId = Math.floor(Math.random() * 100) + 1;
		localStorage.setItem(STORAGE_KEYS.ID, bucketId);
	} else {
		bucketId = Number(bucketId);
	}

	return bucketId;
};

/**
 * Attempts to read bucket config from local storage
 */
export const readBucketConfigFromLocalStorage = () => {
	const config = localStorage.getItem(STORAGE_KEYS.CONFIG);
	if (!config) return null;
	const match = config.match(/^(\d{8}_\d+)_(.*)$/);
	if (match === null) return null;
	return {
		configId: match[1],
		toggles: match[2].length ? match[2].split("|") : [],
	};
};

/**
 * Writes the given config to local storage as a formatted string,
 * and returns a formatted config object.
 * @param {String} configId The config ID
 * @param {Array} toggleArray The array of toggle strings
 */
const writeBucketConfigToLocalStorage = (configId, toggleArray) => {
	const s = `${configId}_${toggleArray.join("|")}`;
	localStorage.setItem(STORAGE_KEYS.CONFIG, s);
	return {
		configId,
		toggles: toggleArray,
	};
};

export const getBucketConfigOverrides = () => {
	let overrides = [];

	const localStorageOverrides = localStorage.getItem(STORAGE_KEYS.OVERRIDE);
	if (
		typeof localStorageOverrides === "string" &&
		localStorageOverrides.length
	) {
		overrides = localStorageOverrides
			.split("|")
			.map((override) => {
				const matchRegex = new RegExp("^(\\w+)=(0|1)$");
				if (!matchRegex.test(override)) return null;

				const match = override.match(matchRegex);
				return {
					toggleKey: match[1],
					value: Number(match[2]),
					source: "localstorage",
				};
			})
			.filter((override) => override !== null);
	}

	// Load the overrides.
	const search = new URLSearchParams(window.location.search);
	for (let [newKey, newValue] of search.entries()) {
		if (/^\w+$/.test(newKey) && /^[0|1]$/.test(newValue)) {
			overrides.push({
				toggleKey: newKey,
				value: Number(newValue),
				source: "url",
			});
		}
	}

	return overrides;
};

export const writeBucketConfigOverridesToLocalStorage = (overrides = []) => {
	const s = overrides
		.filter((override) => {
			return (
				override.source === "localstorage" &&
				/^\w+$/.test(override.toggleKey) &&
				[0, 1].includes(override.value)
			);
		})
		.map((override) => `${override.toggleKey}=${String(override.value)}`)
		.join("|");

	localStorage.setItem(STORAGE_KEYS.OVERRIDE, s);
};

export const getBucketConfig = () => {
	return new Promise((res, rej) => {
		const bucketId = getBucketId();

		// Check the bucket ID.
		if (typeof bucketId !== "number" || bucketId < 1 || bucketId > 100) {
			return rej({ message: "Invalid bucket ID", data: { bucketId } });
		}

		const bucketURL = getBucketURL(bucketId);

		paxios
			.get(bucketURL)
			.then((response) => {
				const { configId, toggles } = response.data;

				if (typeof configId !== "string" || !configId.length) {
					return rej({
						message: "Config ID string received is invalid",
						data: { configId },
					});
				}

				if (!/^\d{8}_\d+$/.test(configId)) {
					return rej({
						message:
							"Config ID string received is in an incorrect format",
						data: { configId },
					});
				}

				if (typeof toggles !== "string") {
					return rej({
						message: "Toggles string received is invalid",
						data: { toggles },
					});
				}

				if (toggles.length && !/^[a-zA-Z0-9_\|]+$/.test(toggles)) {
					return rej({
						message:
							"Toggles string received is in an incorrect format",
						data: { toggles },
					});
				}

				const toggleArray = toggles.length ? toggles.split("|") : [];

				let currentConfig = readBucketConfigFromLocalStorage();
				if (
					currentConfig === null ||
					currentConfig.configId != configId
				) {
					currentConfig = writeBucketConfigToLocalStorage(
						configId,
						toggleArray
					);
				}

				return res(currentConfig);
			})
			.catch((error) => {
				return rej({
					message: "Paxios error",
					data: { error },
				});
			});
	});
};

/**
 * Takes a toggle key string and checks if the toggle is enabled,
 * either via config or via override. Note that unlike the getter, this
 * does not check the VueX store, and is only meant to be used when the
 * getter cannot be used (although in theory it should almost always
 * return the same value).
 * @param {String} toggleKey The toggle key to check
 */
export const isFeatureEnabled = (toggleKey) => {
	const overrides = getBucketConfigOverrides();

	// The highest priority is the override, so check that first.
	const overrideValue = overrides.find(
		(override) => override.toggleKey === toggleKey
	);
	if (overrideValue !== undefined) return overrideValue.value === 1;

	const localConfig = readBucketConfigFromLocalStorage();
	if (localConfig === null) return false;

	// Next check if this key is among the toggles, and return the resulting boolean.
	return localConfig.toggles.includes(toggleKey);
};
