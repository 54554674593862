export default {
	window: {
		windowHeight: null,
		windowWidth: null,
		scrollY: 0,
	},
	query: "",
	navigation: {
		current: null,
	},
	domElements: {
		headerOffset: 0,
		topAdHeight: 0,
		headerHeight: 0,
		visibleHeaderHeight: 0,
	},
	minLevel: null,
	maxLevel: null,
	dailyPuzzles: {
		dailyPuzzlesList: [],
		currentPage: 1,
		maxPage: 1,
	},
};
