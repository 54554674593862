import { isEmpty } from "@/common/functions/objectUtils";

export const readIndexedDb = (databaseKey, objectStoreKey, store) => {
	if (typeof indexedDB === "undefined") {
		return;
	}

	var request = indexedDB.open(databaseKey);
	var db;

	if (isEmpty(databaseKey)) {
		return;
	}

	request.onerror = function(event) {
		store.commit("SET_PREVIOUSLY_LOGGED_IN", false);
	};

	request.onsuccess = function(event) {
		db = event.target.result;
		readDatabaseEntry(objectStoreKey, store);
	};

	const readDatabaseEntry = (objectStoreKey, store) => {
		if (isEmpty(objectStoreKey) || !db.objectStoreNames.length) {
			return;
		}

		var transaction = db.transaction(objectStoreKey, "readonly");
		var table = transaction.objectStore(objectStoreKey);
		var getAllEntriesRequest = table.getAll();

		getAllEntriesRequest.onsuccess = function(event) {
			const entries = event.target.result;
			const previouslyLoggedIn = entries.some((entry) =>
				entry.fbase_key.includes("firebase:authUser")
			);
			store.commit("SET_PREVIOUSLY_LOGGED_IN", previouslyLoggedIn);
		};

		getAllEntriesRequest.onerror = function() {
			store.commit("SET_PREVIOUSLY_LOGGED_IN", false);
		};
	};
};
