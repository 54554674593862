import Vue from "vue";

export default {
	updateWindowProperties: function(state, payload) {
		if (payload.windowHeight) {
			state.window.windowHeight = payload.windowHeight;
		}
		if (payload.windowWidth) {
			state.window.windowWidth = payload.windowWidth;
		}
		if (payload.scrollY) {
			state.window.scrollY = payload.scrollY;
		}
	},
	updateQuery: function(state, payload) {
		state.query = payload;
	},
	updateFromSearch: function(state, payload) {
		state.fromSearch = payload;
	},
	updateCurrentPage: function(state, value) {
		state.navigation.current = value;
	},
	setNumberOfLevels: function(state, payload) {
		state.minLevel = payload.minLevel;
		state.maxLevel = payload.maxLevel;
	},
	setHeaderOffset: function(state, value) {
		state.domElements.headerOffset = value;
		if (
			typeof value === "number" &&
			typeof state.domElements.headerHeight === "number"
		) {
			state.domElements.visibleHeaderHeight =
				value + state.domElements.headerHeight;
		}
	},
	setDailyPuzzleList: function(state, value) {
		Vue.set(state.dailyPuzzles, "dailyPuzzlesList", value);
	},
	setDailyPuzzleCurrentPage: function(state, value) {
		state.dailyPuzzles.currentPage = value;
	},
	setDailyPuzzleMaxPage: function(state, value) {
		state.dailyPuzzles.maxPage = value;
	},
	setTopAdHeight: function(state, value) {
		state.domElements.topAdHeight = value;
	},
	setHeaderHeight: function(state, value) {
		state.domElements.headerHeight = value;
		state.domElements.visibleHeaderHeight = value;
	},
};
