import Vue from "vue";
import { CANONICAL_HOST, CANONICAL_PROTOCOL } from "@/common/consts";
import _ from "lodash";

/**
 * A wrapper function to track custom events in GA. For more
 * info on what these params mean, please consult the official
 * Google documentation:
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=en
 * @param {object} eventData An object containing the category, action, label, value. Only the first two are mandatory.
 */
export const trackEvent = (eventAction, eventData) => {
	if (typeof window.__PRERENDER_INJECTED !== "undefined") return false;
	if (typeof Vue.$gtag !== "object" || typeof Vue.$gtag.event !== "function")
		return false;

	// The GA tracking requires at least the category and the action of the event.
	if (!eventData.eventCategory || !eventAction) {
		return false;
	}

	Vue.$gtag.event(eventAction, {
		event_category: eventData.eventCategory || "",
		event_label: eventData.eventLabel || "",
		event_value: eventData.eventValue || null,
		non_interaction: Boolean(eventData.nonInteraction),
	});
};

/**
 * Create a canonical URL from Vue router's current route
 * @param {String} route - The Vue Router's current route object
 * @return {String} The canonical URL with fixed protocol and no trailing slash
 */
export const canonical = (route) => {
	// Lowercase route (except in enye-related paths)
	const map = Array.prototype.map;
	let path = map
		.call(route.path, (letter) => {
			return letter.toLowerCase();
		})
		.join("");

	// Fix missing leading slash in relative path
	path = path.charAt(0) !== "/" ? `/${path}` : path;
	let url = `${CANONICAL_PROTOCOL}//${CANONICAL_HOST}${path}`;
	if (!url.endsWith("/")) {
		url = `${url}/`;
	}
	return {
		rel: "canonical",
		href: url,
	};
};

export const getHeaderHeight = (isSmallDevice) => {
	const mobileHeaderHeight = 64;
	const desktopHeaderHeight = 70;
	return isSmallDevice ? mobileHeaderHeight : desktopHeaderHeight;
};

export const trackOverLimitQuery = _.debounce(
	(store, query) => {
		store.dispatch("trackEvent", {
			eventCategory: "Search bar",
			eventAction:
				"Search for a level number higher than the latest one we have published",
			eventLabel: query,
		});
	},
	3000,
	{
		leading: true,
		trailing: false,
	}
);

export const capitalizeFirstLetter = (string) => {
	if (typeof string !== "string") return "";
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateBreadcrumbStructuredDataMarkup = (items = []) => {
	const baseUrl = `${CANONICAL_PROTOCOL}//${CANONICAL_HOST}`;
	const allItems = [
		{
			name: "Home",
			path: `/`,
		},
		...items,
	];

	return {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: allItems.map((item, i) => ({
			"@type": "ListItem",
			position: i + 1,
			name: item.name,
			item: `${baseUrl}${item.path}${
				item.path?.endsWith?.("/") ? "" : "/"
			}`,
		})),
	};
};

export {
	getAdPageType,
	getAdPlacement,
	getAdPosition,
	getAditudeBaseId,
} from "./adUtils";

export {
	dateAsObject,
	currentDateAsObject,
	dateObjectAsDailyPuzzleLink,
} from "./dateUtils";

export { isEmpty } from "./objectUtils";

export { isClientSide, writeCCPACookie, readCCPACookie } from "./htmlUtils";

export { readIndexedDb } from "./indexedDbUtils";
