/**
 * Write the CCPA Privacy string into a cookie to be read by the API.
 * @param {string} value The value of the cookie to be written. Defaults to 1--- as per understood specs.
 */
export function writeCCPACookie(value = "1---") {
	let d = new Date();
	d.setTime(d.getTime() + 10 * 365 * 24 * 60 * 60 * 1000); // Make it roughly 10 years from now.
	const expires = "expires=" + d.toUTCString();
	document.cookie = `usprivacy=${value};${expires};path=/;Domain=.yourdictionary.com;SameSite=Lax;secure`;
}

/**
 * Read the CCPA Privacy string from the 'usprivacy' cookie.
 */
export function readCCPACookie() {
	var cookieValue = "";
	var cookieName = "usprivacy=";
	var cookieArray = document.cookie.split(";");
	for (var i = 0; i < cookieArray.length; i++) {
		var cookie = cookieArray[i];
		while (cookie.charAt(0) == " ") {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(cookieName) == 0) {
			cookieValue = cookie.substring(cookieName.length, cookie.length);
			break;
		}
	}

	// If the cookie cannot be found or has an invalid value, default it back to 1---, the CCPA default privacy string value.
	if (!/^[1][nNyY-][nNyY-][nNyY-]$/.test(cookieValue)) {
		cookieValue = "1---";
	}

	return cookieValue;
}

/**
 * Check if the current code is executed on the client or server side.
 * @return {Boolean} true if the code is executed on the client side (browser). False otherwise (server side pre-rendering)
 */
export function isClientSide() {
	return typeof window.__PRERENDER_INJECTED === "undefined";
}
