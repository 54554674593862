import { isEmpty } from "@/common/functions";

export default {
	state: {
		configId: "",
		toggles: [],
		overrides: [],
	},
	mutations: {
		SET_FEATURE_TOGGLE_BUCKET_CONFIG: (state, config) => {
			state.configId = config.configId;
			state.toggles = config.toggles;
		},
		SET_FEATURE_TOGGLE_BUCKET_OVERRIDES: (state, overrides) => {
			state.overrides = overrides;
		},
	},
	getters: {
		isFeatureEnabled: (state) => (toggleKey) => {
			// The highest priority is the URL override, so check that first.
			const urlOverrideValue = state.overrides.find((override) => {
				return (
					override.toggleKey === toggleKey &&
					override.source === "url"
				);
			});
			if (!isEmpty(urlOverrideValue)) return urlOverrideValue.value;

			// Then check the localstorage override.
			const localStorageOverrideValue = state.overrides.find(
				(override) => {
					return (
						override.toggleKey === toggleKey &&
						override.source === "localstorage"
					);
				}
			);
			if (!isEmpty(localStorageOverrideValue))
				return localStorageOverrideValue.value;

			// Next check if this key is among the toggles, and return the resulting boolean.
			return state.toggles.includes(toggleKey);
		},
		allToggles: (state) => {
			const all = {};
			state.toggles.forEach((toggleKey) => {
				all[toggleKey] = {
					toggleKey,
					value: 1,
					source: "config",
				};
			});

			state.overrides
				.filter((override) => override.source === "localstorage")
				.forEach((override) => {
					all[override.toggleKey] = {
						toggleKey: override.toggleKey,
						value: override.value,
						source: override.source,
					};
				});

			state.overrides
				.filter((override) => override.source === "url")
				.forEach((override) => {
					all[override.toggleKey] = {
						toggleKey: override.toggleKey,
						value: override.value,
						source: override.source,
					};
				});

			return Object.entries(all)
				.map(([toggleKey, toggle]) => toggle)
				.sort((a, b) => a.toggleKey - b.toggleKey);
		},
	},
};
