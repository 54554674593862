<template>
	<div class="container">
		<section id="dynamic-page">
			Loading...
		</section>
	</div>
</template>

<script>
import metaInfo from "./meta";
export default {
	name: "DynamicPage",
	metaInfo,
};
</script>
