import {
	PAGES,
	AD_LAYOUTS,
	AD_LAYOUT_STUBS,
	AD_PAGETYPES,
	AD_POSITIONS,
	AD_PLACEMENTS,
	ADITUDE_BASE_IDS,
} from "@/common/consts";

/**
 * Takes a route name and returns a pagetype value.
 * @param {string} routeName The name of the route
 * @returns {string} The pagetype value
 */
export const getAdPageType = (routeName) => {
	switch (routeName) {
		case PAGES.LEVEL:
		case PAGES.DAILY_PUZZLE_ANSWERS:
			return AD_PAGETYPES.RESULTS;
		default:
			return AD_PAGETYPES.HOMEPAGE;
	}
};

/**
 * Takes an ad layout stub and tries to auto-guess
 * a placement value for it.
 * @param {string} stub The ad layout stub
 * @returns {string?} A possible placement value, or null if one can't be found.
 */
export const getAdPlacement = (stub) => {
	switch (stub) {
		case AD_LAYOUT_STUBS.RIGHT_RAIL:
			return AD_PLACEMENTS.DT_RIGHT_1;
		case AD_LAYOUT_STUBS.ADHESION:
			return AD_PLACEMENTS.MOB_ADHESION;
		case AD_LAYOUT_STUBS.DT_IN_CONTENT:
			return AD_PLACEMENTS.DT_IN_CONTENT_1;
		case AD_LAYOUT_STUBS.TOP_LEADERBOARD:
			return AD_PLACEMENTS.DT_TOP;
		case AD_LAYOUT_STUBS.MOB_IN_CONTENT:
			return AD_PLACEMENTS.MOB_IN_CONTENT_1;
		default:
			return null;
	}
};

export const getAditudeBaseId = (placement) => {
	switch (placement) {
		case AD_PLACEMENTS.MOB_ADHESION:
		case AD_PLACEMENTS.DT_TOP:
			return ADITUDE_BASE_IDS.ANCHOR;

		case AD_PLACEMENTS.DT_IN_CONTENT_1:
		case AD_PLACEMENTS.MOB_IN_CONTENT_1:
			return ADITUDE_BASE_IDS.IN_CONTENT_1;

		case AD_PLACEMENTS.DT_IN_CONTENT_2:
		case AD_PLACEMENTS.MOB_IN_CONTENT_2:
			return ADITUDE_BASE_IDS.IN_CONTENT_2;

		case AD_PLACEMENTS.DT_RIGHT_1:
			return ADITUDE_BASE_IDS.RIGHT_1;

		case AD_PLACEMENTS.DT_RIGHT_2:
			return ADITUDE_BASE_IDS.RIGHT_2;

		case AD_PLACEMENTS.DT_RIGHT_3:
			return ADITUDE_BASE_IDS.IN_CONTENT_3;

		case AD_PLACEMENTS.DT_IN_CONTENT_3:
		case AD_PLACEMENTS.MOB_IN_CONTENT_3:
			return ADITUDE_BASE_IDS.IN_CONTENT_3;

		case AD_PLACEMENTS.DT_IN_CONTENT_4:
		case AD_PLACEMENTS.MOB_IN_CONTENT_4:
			return ADITUDE_BASE_IDS.IN_CONTENT_4;

		default:
			if (isFeatureEnabled("DebugMode") && isFeatureEnabled("DebugAds")) {
				console.debug(
					`Unable to find an Aditude base ID for ${placement}, defauling to "pb-slot-incontent-1".`
				);
			}
			return ADITUDE_BASE_IDS.IN_CONTENT_1;
	}
};

/**
 * Takes an ad placement value, and tries to regex-match
 * it to get the ad position
 * @param {string} placement The ad placement value
 * @returns {string?} The position value
 */
export const getAdPosition = (placement) => {
	for (let position of Object.values(AD_POSITIONS)) {
		const regex = RegExp(position, "i");
		if (regex.test(placement)) {
			return position;
		}
	}

	return null;
};
