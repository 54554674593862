import axios from "axios";
import { VERSION_CHECK_POLL_FREQ, VERSION_CHECK_ERROR } from "@/common/consts";

export default {
	lastCheck: 0,
	checkRequired() {
		this.lastCheck =
			parseInt(window.$cookies.get("_WF_LVC")) || this.lastCheck;
		return +new Date() > this.lastCheck + VERSION_CHECK_POLL_FREQ;
	},
	check() {
		return new Promise((resolve, reject) => {
			if (
				this.checkRequired() &&
				typeof window.__PRERENDER_INJECTED === "undefined"
			) {
				this.lastCheck = +new Date();
				window.$cookies.set("_WF_LVC", this.lastCheck);
				axios
					.get(
						`/static/version.json?t=${Math.floor(
							this.lastCheck / 10000
						)}`
					) // 10 seconds granularity.
					.then((version) => {
						if (
							typeof version.data.build !== "undefined" &&
							typeof window.appVersion !== "undefined" &&
							version.data.build !== window.appVersion
						) {
							let e = new Error("Client App requires reload.");
							e.name = VERSION_CHECK_ERROR;
							reject(e);
						}
					})
					.finally(() => resolve(true));
			} else {
				resolve(true);
			}
		});
	},
};
