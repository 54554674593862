// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueGtag from "vue-gtag";
import VueGtm from "@gtm-support/vue2-gtm";
import vueCookies from "vue-cookies";

const App = () =>
	import(/* webpackChunkName: "component-app" */ "@/components/app");
import router from "@/router";
import store from "@/store";
import i18n from "@/common/multilanguage/i18n";
import { GTAG_CONFIG, STAGE } from "@/common/consts";
import { isFeatureEnabled } from "@/common/feature-toggling";
import nodeConfig from "../package.json";
import {
	initAnalytics,
	trackCLS,
	trackFID,
	trackLCP,
	trackFCP,
} from "@lovetoknow/ltk-analytics";

import "./mixins";

if (typeof window.__PRERENDER_INJECTED === "undefined") {
	Vue.use(VueGtag, GTAG_CONFIG, router);

	const analytics = initAnalytics({
		appName: "wscapes",
		appEnvironment: STAGE,
		expTrack: true,
		pageTrack: true,
		version: nodeConfig.version,
		debug: isFeatureEnabled("debugCoreVitals"),
		meta_url:
			"https://us-central1-custom-site-analytics.cloudfunctions.net/router/metaInfo",
		page_exp_url:
			"https://us-central1-custom-site-analytics.cloudfunctions.net/router/pageExp",
		page_evt_url:
			"https://us-central1-custom-site-analytics.cloudfunctions.net/router/pageEvt",
		custom_evt_url:
			"https://us-central1-custom-site-analytics.cloudfunctions.net/router/customEvt",
	});
	window.$ltkAnalytics = analytics;

	if (isFeatureEnabled("trackCoreVitals")) {
		trackCLS();
		trackFID();
		trackLCP();
		trackFCP();
	}
}

Vue.use(vueCookies);

if (
	typeof window.__PRERENDER_INJECTED === "undefined" &&
	typeof window.__TEST__ === "undefined"
) {
	Vue.use(VueGtm, {
		id: "GTM-5SVHM3B",
		debug: isFeatureEnabled("debugGTM"),
		vueRouter: router,
	});

	/**
	 * @note
	 * Although we are firing the "libload-aditude" custom event here,
	 * this is for future purposes. For now we are loading the Aditude
	 * script tag via the local libLoader library instead of GTM, as
	 * the Aditude team has requested we don't use GTM.
	 */
	if (typeof libloaderPromise !== "undefined") {
		timerPromise.then(() => {
			dataLayer.push({ event: "libload-aditude" });
			libloaderPromise.then(() => {
				libLoader.loadAditude();
			});
		});
	}
}

/* eslint-disable no-new */
new Vue({
	el: "#app",
	i18n,
	router,
	store,
	components: { App },
	template: "<App/>",
	metaInfo: {
		script: [
			{
				vmid: "jsonld-organization",
				type: "application/ld+json",
				json: {
					"@context": "https://schema.org",
					"@type": "Organization",
					name: "Wordscapes by YourDictionary",
					url: "https://wordscapes.yourdictionary.com",
					logo:
						"https://wordscapes.yourdictionary.com/static/images/logo_horizontal.png",
					address: {
						"@type": "PostalAddress",
						addressLocality: "Vero Beach, FL",
						postalCode: "32960-4706",
						streetAddress: "1275 US Highway 1 Unit 2-6084",
					},
					parentOrganization: {
						"@type": "Organization",
						"@id": "https://www.lovetoknowmedia.com/",
						name: "LoveToKnow Media",
						url: "https://www.lovetoknowmedia.com/",
						sameAs: [
							"https://www.linkedin.com/company/lovetoknowmedia/",
							"https://www.instagram.com/lovetoknowmedia/",
							"https://www.facebook.com/lovetoknowmedia/about",
							"https://x.com/lovetoknowmedia",
							"https://www.crunchbase.com/organization/lovetoknow-corp",
						],
						logo: {
							"@type": "ImageObject",
							url:
								"https://www.lovetoknowmedia.com/wp-content/uploads/2021/05/LTK_MEDIA_Logo-2.png",
						},
						address: {
							"@type": "PostalAddress",
							addressLocality: "Vero Beach, FL",
							postalCode: "32960-4706",
							streetAddress: "1275 US Highway 1 Unit 2-6084",
						},
					},
				},
			},
		],
	},
});
