/**
 * Checks if obj is an empty Object
 * @param {Object} obj
 * @return {Boolean} True if obj is empty. False if not
 */
export function isEmpty(obj) {
	return (
		typeof obj === "undefined" ||
		!obj ||
		(obj instanceof Array && obj.length === 0) ||
		(Object.keys(obj).length === 0 && obj.constructor === Object)
	);
}
