import { trackEvent } from "@/common/functions";

export default {
	/** Track a Google Event. */
	trackEvent: function(context, payload) {
		const { eventCategory, eventAction, eventLabel, eventValue } = payload;
		const eventData = {
			eventCategory,
			eventLabel,
			eventValue,
		};
		trackEvent(eventAction, eventData);
	},
	updateNumberOfLevels: function(context, payload) {
		context.commit("setNumberOfLevels", payload);
	},
};
